import { gql } from '@apollo/client';
import { commonAddressFields } from '@poly/admin-ui';

export const clientFieldsForFilters = gql`
  fragment clientFieldsForFilters on Client {
    _id
    name
    apps
  }
`;

export const clientAccountFields = gql`
  fragment clientAccountFields on Client {
    _id
    apps
    name
    status
    cardNumber
    nickName
    sapGLCode
    submitProjectWebFormUrl
    address {
      ...commonAddressFields
    }
    addressTwo
    phone
    fax
    website
    contractFile {
      url
      fileName
    }
    remarks
    financial {
      invoiceto
      ourvendorno
      division {
        _id
        code
        name
      }
      costCenter
      terms
      invoiceAttentionTo
      accountingEmail
      spendReportType
      invoiceAttentionToProjectRequestor
      vaAccountNumber
      vaSplitPercent
      exemptSalesTax
      exemptSalesTaxFile {
        url
        fileName
      }
    }
    tmMarkupRules {
      upTo
      percent
    }
    staffRates {
      afterHoursCall
      customRates {
        description
        rate
        overtime
        doubleTime
      }
    }
    parentClient {
      _id
      name
    }
    childClients {
      _id
      name
      status
    }
    accountsReceivableBalance
    enableReportOnlyProjectType
    enableGlCodes
    configs {
      enableHousekeepingProjectType
      enableDistricts
      defaultProjectType
      clientPortalApp {
        allowProjectCreation
        showPMsAndAssets
        projectServiceType {
          _id
          name
        }
      }
      requestsApp {
        branding
        projectType
        projectFeeType
        projectServiceType {
          _id
          name
        }
      }
      assetScannerApp {
        allowReplacementCost
        allowProjectCreation
      }
      adminApp {
        allowProjectCreation
      }
      housekeepingCalendar {
        startOfYear
        description
      }
      propertyFields {
        buildingInfo {
          lockBoxCode
          occupancy
          buildingSize
          ownership
          powerRequirements
          landlordResponsibility
          buildingType
          parcelTaxId
        }
      }
      notifications {
        serviceRequestConfirmationEmail {
          toSiteContact
          toRequester
          toClientManager
        }
        projectClientStatusUpdateDefaultMailTo {
          toSiteContact
          toRequester
          toClientManager
        }
      }
      logoImage {
        url
        fileName
      }
      clientInvoicing {
        reportOnlyClientInvoiceCreation
        companyLogo {
          url
          fileName
        }
        address {
          ...commonAddressFields
        }
        addressTwo
        invoiceEmailRecipients
        email {
          enabled
        }
      }
      requiresSignOffSheet
      enablePurchaseOrder
      isPORequiredForProject
      requesterSurvey {
        enabled
        questions {
          type
          question
        }
      }
      projectPriorities {
        id
        name
        amount
        unit
        supplierArrivalUnit
        supplierArrivalAmount
      }
    }
    enableChildAccounts
    enablePropertyLocationHierarchy
    enableCostCenter
    clientManagers {
      _id
      fullName
    }
    isUMCAccountFormat
    clientGroupEmail
    manager {
      _id
      profile {
        fullName
      }
    }
    districts {
      _id
      name
    }
    woCharge
    bidRules {
      type
      label
      amount
      assetsNumber
      isRuleActive
    }
  }

  ${commonAddressFields}
`;
