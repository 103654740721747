import React from 'react';
import * as R from 'ramda';
import { useMapConfigToTableProps } from '@poly/admin-ui';
import { arrayOf, bool, shape, string } from 'prop-types';

import { divisionsTableConfig } from './divisionsTableConfig.js';
import { DivisionTableS } from './components/common.js';

export function DivisionsTable({ divisions, loading, ...tableSortingProps }) {
  const tableProps = useMapConfigToTableProps(
    R.identity,
    divisionsTableConfig,
    divisions,
  );

  return (
    <DivisionTableS
      {...tableProps}
      {...tableSortingProps}
      isLoading={loading}
    />
  );
}

DivisionsTable.propTypes = {
  loading: bool.isRequired,
  divisions: arrayOf(
    shape({
      _id: string.isRequired,
      name: string.isRequired,
      status: string.isRequired,
    }),
  ),
};
