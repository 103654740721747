import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useReactiveQuery } from '@poly/client-utils/src/hooks/useReactiveQuery.js';
import { useSetItemsCount } from '@poly/admin-ui/src/hooks/useSetItemsCount.js';
import { usePaginationParams } from '@poly/admin-ui/src/hooks/usePaginationParams.js';

const GET_DIVISIONS_QUERY = gql`
  query GET_DIVISIONS($input: DivisionsInput) {
    divisions(input: $input) {
      hits {
        _id
        name
        status
      }
      total
    }
  }
`;

const DIVISIONS_CHANGED_SUBSCRIPTION = gql`
  subscription DIVISIONS_CHANGED_SUBSCRIPTION($input: CollectionSearchParams) {
    searchDivisionChanged(input: $input) {
      id
      type
    }
  }
`;

export const useDivisionsQuery = (sort) => {
  const paginationParams = usePaginationParams();

  const { data, loading } = useReactiveQuery(
    GET_DIVISIONS_QUERY,
    DIVISIONS_CHANGED_SUBSCRIPTION,
    {
      queryOptions: { variables: { input: { sort, ...paginationParams } } },
      subscriptionOptions: {},
    },
  );

  useSetItemsCount(R.pathOr(0, ['divisions', 'total']), data);

  const divisions = R.pathOr([], ['divisions', 'hits'], data);

  return { divisions, loading };
};
