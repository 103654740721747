import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { number, string, shape, element, bool, func } from 'prop-types';
import { Loader, Status } from '@poly/admin-book';
import {
  SidebarTabTableWrapper,
  useSearchTabLogic,
  SearchInputComp,
  SidebarSubTabs,
  SidebarWidth,
  SidebarRow,
  ALL,
} from '@poly/admin-ui';
import { formatCount, forceTitleCase } from '@poly/utils';

import {
  SectionLabel,
  DoubleTabsWrapper,
  CommonProjectsTabTable,
} from '../commonSidebarComponents.js';
import {
  projectStatusesUI,
  projectStatusesColors,
} from '../../../modules/core/constants/projects.js';
import { recurringSubTabsConfig } from '../../../modules/tabs/common.js';
import { useSearchSubTabsMastersQuery } from './useSearchSubTabsMastersQuery.js';
import { SidebarMastersTabXlsExportButton } from './SidebarMastersTabXlsExportButton/SidebarMastersTabXlsExportButton.js';

const SidebarMastersTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

function CommonMastersTabTitle({ status, ...restProps }) {
  const { count } = useSearchSubTabsMastersQuery({ status, ...restProps });

  const title = `${projectStatusesUI[status]} (${formatCount(count)})`;

  return <Status color={projectStatusesColors[status]} text={title} />;
}

CommonMastersTabTitle.propTypes = { status: string.isRequired };

function CommonMastersTab(props) {
  const { loading, masterProjects, tableProps } =
    useSearchSubTabsMastersQuery(props);

  const { status, setCurrentTab, setFoundMasterProjects } = props;

  useEffect(() => {
    if (setCurrentTab) {
      setCurrentTab(status);
    }
  }, [status]);

  useEffect(() => {
    if (setFoundMasterProjects) {
      setFoundMasterProjects(masterProjects);
    }
  }, [masterProjects?.length]);

  if (loading) return <Loader />;

  return <CommonProjectsTabTable {...tableProps} />;
}

CommonMastersTab.propTypes = {
  status: string,
  setCurrentTab: func,
  setFoundMasterProjects: func,
};

export function SidebarMastersTab({
  tabTitlePrefix,
  tabWidth,
  entity,
  toolBar,
  isCard,
}) {
  const [currentTab, setCurrentTab] = useState(ALL);
  const [foundMasterProjects, setFoundMasterProjects] = useState([]);
  const { searchTerm, ...inputProps } = useSearchTabLogic('masters');

  const titlePrefix = tabTitlePrefix || forceTitleCase(entity.name);

  const sidebarTabTitle = `${titlePrefix} Master Recurring Projects`;

  return (
    <DoubleTabsWrapper width={tabWidth} isCard={isCard}>
      <SidebarRow justify align>
        <SectionLabel>{sidebarTabTitle}</SectionLabel>
        <SidebarMastersTabWrapper>
          <SidebarMastersTabXlsExportButton
            currentTab={currentTab}
            foundMasterProjects={foundMasterProjects}
          />
          <SearchInputComp {...inputProps} />
        </SidebarMastersTabWrapper>
      </SidebarRow>
      <SidebarTabTableWrapper offset={48}>
        <SidebarSubTabs
          tabs={recurringSubTabsConfig.map(([status, title]) => [
            <CommonMastersTabTitle {...{ status, entity, searchTerm }} />,
            title,
            <CommonMastersTab
              {...{
                status,
                entity,
                searchTerm,
                setCurrentTab,
                setFoundMasterProjects,
              }}
            />,
          ])}
          defaultValue="all"
          toolBar={toolBar}
        />
      </SidebarTabTableWrapper>
    </DoubleTabsWrapper>
  );
}

SidebarMastersTab.propTypes = {
  isCard: bool,
  toolBar: element,
  tabWidth: number,
  entity: shape({
    _id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  tabTitlePrefix: string,
};
SidebarMastersTab.defaultProps = { tabWidth: SidebarWidth * 2 };
