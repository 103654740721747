import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { MoneyInput } from '@poly/admin-ui';
import { Input, createGetFormFieldWidth } from '@poly/admin-book';
import { ProjectType } from '@poly/constants';

import { isProjectOfType } from '../utils/sections.js';
import { SupplierLinkComp } from '../../../../../modules/tables/columns/suppliers.js';
import { AdminPOSelector } from '../components/AdminPOSelector.js';
import {
  adminPurchaseOrderValidationFunc,
  adminPurchaseOrderValidators,
} from './validateAminPurchaseOrder.js';

const SupplierLinkCompS = styled(SupplierLinkComp)`
  font-size: 12px;
  line-height: 18px;
`;

// define 3 columns with 5px between
const getFormColumnWidth = createGetFormFieldWidth(3, 5);
const columnWidth1 = getFormColumnWidth(1);

export const getSuppliersNTESection = (startingRow, suppliers) => {
  const supplierNTESections = suppliers.map((supplier, index) => [
    {
      label: 'Supplier Not to Exceed',
      layout: { row: startingRow + index, width: '23%' },
      order: 1,
      field: {
        name: `suppliers.${index}.nte`,
        Component: MoneyInput,
      },
      leaveValues: true,
    },
    {
      label: '',
      layout: {
        row: startingRow + index,
        margin: ' 25px 0 0 0',
      },
      order: 2,
      field: {
        name: `supplierLink.${index}._id`,
        Component: () => <SupplierLinkCompS {...supplier} />,
      },
    },
  ]);

  return R.flatten(supplierNTESections);
};

export const clientNotToExceedFormFieldConfig = {
  label: 'Client Not to Exceed',
  field: {
    name: 'nte',
    Component: MoneyInput,
  },
  leaveValues: true,
  renderIf: R.complement(isProjectOfType(ProjectType.BID)),
};

// isRenderClientPO :: Project -> Boolean
const isRenderClientPO = R.both(
  R.propSatisfies(R.isNil, 'parent'),
  R.either(
    isProjectOfType(ProjectType.WORK_ORDER),
    isProjectOfType(ProjectType.BID),
  ),
);

export const clientRefNoPoFormFieldConfig = {
  label: 'Client Ref # / PO',
  layout: { row: 1, width: columnWidth1 },
  order: 2,
  field: {
    name: 'clientReferenceNumber',
    Component: Input,
  },
  leaveValues: true,
  renderIf: R.both(
    isRenderClientPO,
    R.complement(R.path(['client', 'configs', 'enablePurchaseOrder'])),
  ),
};

export const clientPOSelectFormFieldConfig = {
  label: 'Client Ref # / PO',
  layout: { row: 1, width: columnWidth1 },
  order: 2,
  field: {
    name: 'adminPurchaseOrderId',
    withFormData: true,
    withChangeFieldValue: true,
    Component: AdminPOSelector,
  },
  leaveValues: true,
  renderIf: R.both(
    isRenderClientPO,
    R.path(['client', 'configs', 'enablePurchaseOrder']),
  ),
  validators: adminPurchaseOrderValidators,
  validateFunction: adminPurchaseOrderValidationFunc,
};

export const bidAmountFormFieldConfig = {
  label: 'Bid Amount',
  layout: { row: 1, width: columnWidth1 },
  order: 3,
  field: {
    name: 'bidAmount',
    additionalProps: { allowNegative: true },
    Component: MoneyInput,
  },
  leaveValues: true,
  renderIf: isProjectOfType(ProjectType.BID),
};
